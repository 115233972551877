import { faEye, faPenToSquare } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { CSVLink } from 'react-csv'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { FaStar } from 'react-icons/fa6'
import { ImCross } from 'react-icons/im'
import { MdVerified } from 'react-icons/md'
import { TbCloudDownload } from 'react-icons/tb'
import { Link } from 'react-router-dom'
import { cdnUrl } from '../../../utils/api'
import Pagination from '../../Pagination/Pagination'
import { PopUp } from '../../Resources'
import Table from '../../Resources/Table/Table'
import { EndDate, StartDate } from '../../Resources/dateFormatter'
import classes from './ActiveSellers.module.css'

export default function ActiveSellers(props) {
    // sellers, pageSkip, setPageSkip, pageAll, setCheck, sellerData, check
    const [activePopUp, setActivePopUp] = useState(false)
    const [showAll, setShowAll] = useState(true)
    const [isFeatured, setIsFeatured] = useState(false)
    const [isVerified, setIsVerified] = useState(false)
    useEffect(() => {
        if (isFeatured === false && isVerified === false) {
            setShowAll(true)
        } else {
            setShowAll(false)
        }
    }, [isFeatured, isVerified])
    const userInfoExportData = props.allSellers.map((e) => ({
        name: e.User.name,
        email: e.User.email,
        phone: e.User.phone,
        company: e.Seller.page_name,
    }))
    const handleStartDateChange = (date) => {
        const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm:ss")
        props.setStartDate(StartDate(formattedDate))
    }
    const handleEndDateChange = (date) => {
        const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm:ss")
        props.setEndDate(EndDate(formattedDate))
    }
    const clearDate = () => {
        props.setStartDate('')
        props.setEndDate('')
    }
    return (
        <div className={classes.wrapper}>
            <p className={classes.txt}> Active Seller List ({props.pageAll})</p>
            <div className={classes.filter}>
                <div className={classes.search}>
                    <input
                        type="text"
                        name="search"
                        placeholder="Search by company name..."
                        value={props.companyName}
                        onChange={(e) => props.setCompanyName(e.target.value)}
                    />
                    <input
                        type="number"
                        placeholder="Search by phone number..."
                        value={props.phone}
                        className={classes.phoneSearch}
                        onChange={(e) => props.setPhone(e.target.value)}
                    />
                </div>
                <div className={isFeatured ? classes.active : classes.inactive}>
                    <input
                        type="checkbox"
                        name="featured"
                        value={isFeatured}
                        onChange={(e) => (isFeatured ? setIsFeatured(false) : setIsFeatured(true))}
                        checked={isFeatured === true}
                    />
                    Featured <FaStar className={classes.iconFeatured} />
                </div>
                <div className={isVerified ? classes.active : classes.inactive}>
                    <input
                        type="checkbox"
                        name="verified"
                        value={isVerified}
                        onChange={(e) => (isVerified ? setIsVerified(false) : setIsVerified(true))}
                        checked={isVerified === true}
                    />
                    Verified <MdVerified className={classes.iconVerified} />
                </div>
                <div>
                    <CSVLink
                        data={userInfoExportData}
                        filename={'biztrade-seller-info.csv'}
                        className={classes.exportCsv}>
                        <TbCloudDownload className={classes.csvIcon} />
                        All Seller Info
                    </CSVLink>
                </div>
                <div className={classes.dateWrapper}>
                    <DatePicker
                        className={classes.datePicker}
                        selected={props.startDate}
                        onChange={handleStartDateChange}
                        showIcon
                        placeholderText="Start Date"
                        enableTabLoop={false}
                    />
                    <DatePicker
                        className={classes.datePicker}
                        selected={props.endDate}
                        onChange={handleEndDateChange}
                        showIcon
                        placeholderText="End Date"
                        enableTabLoop={false}
                    />
                    {(props.startDate?.length > 0 || props.endDate?.length > 0) && (
                        <button onClick={clearDate}>
                            <ImCross />
                        </button>
                    )}
                </div>
            </div>
            <Table>
                <thead>
                    <tr className={`${classes.tableRow}`}>
                        <th>Sl.</th>
                        <th>Seller ID</th>
                        <th>Company Name</th>
                        <th>Logo</th>
                        {/* <th>Banner</th> */}
                        <th>Owner </th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>City</th>
                        <th>Category </th>

                        <th>Date</th>
                        <th>Subscription</th>
                        <th>Action</th>
                    </tr>
                </thead>

                <tbody>
                    {props.sellers &&
                        props.sellers.map((data, index) => {
                            if (
                                showAll ||
                                (isFeatured && data.Seller.is_feature) ||
                                (isVerified && data.Seller.is_verify)
                            ) {
                                return (
                                    <tr className={classes.row} key={index}>
                                        <td>
                                            <div></div>
                                            {props.pageSkip + index + 1}
                                        </td>
                                        <td>
                                            BIZ-U{data.Seller.user_id}-S{data.Seller.id + 100}
                                        </td>
                                        <td>
                                            <div id={classes.verified}>
                                                {data.Seller.is_verify ? (
                                                    <MdVerified className={classes.iconVerified} />
                                                ) : (
                                                    ''
                                                )}
                                                {data.Seller.page_name}
                                            </div>
                                        </td>
                                        <td>
                                            {
                                                <img
                                                    alt=""
                                                    className={classes.logo}
                                                    src={
                                                        data.ImgLogo.image_url
                                                            ? data.ImgLogo.image_url
                                                            : `${cdnUrl}/logo/${data.ImgLogo.image_string}`
                                                    }
                                                />
                                            }
                                        </td>
                                        <td>{data.User.name}</td>
                                        <td>
                                            {data?.User?.email.split('@')[0]}
                                            <br />@{data?.User?.email.split('@')[1]}
                                        </td>
                                        <td>{data.User.phone}</td>
                                        <td>{data.Seller.city}</td>
                                        <td>{data.Seller.category}</td>

                                        <td>{data.Seller.created_at.slice(0, 10)}</td>
                                        <td>
                                            <div id={classes.subscription}>
                                                {data.Seller.is_feature ? (
                                                    <FaStar className={classes.iconFeatured} />
                                                ) : (
                                                    ''
                                                )}
                                                {data.Seller.is_basic
                                                    ? 'Basic'
                                                    : data.Seller.is_standard
                                                    ? 'Standard'
                                                    : data.Seller.is_premium
                                                    ? 'Premium'
                                                    : '-'}
                                            </div>
                                        </td>
                                        <td>
                                            <div className={classes.action}>
                                                <Link to={`https://biztradebd.com/companies/${data?.User?.id + 10000}`}>
                                                    <FontAwesomeIcon className={classes.icon} icon={faEye} />
                                                </Link>
                                                <FontAwesomeIcon
                                                    className={classes.iconEdit}
                                                    icon={faPenToSquare}
                                                    onClick={() => {
                                                        setActivePopUp(index)
                                                    }}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            </div>
                                        </td>
                                        {activePopUp === index && (
                                            <td>
                                                <PopUp
                                                    sellerName={data.User.name}
                                                    userId={data.User.id}
                                                    sellerId={data.Seller.id}
                                                    active={data.User.is_active}
                                                    featured={data.Seller.is_feature}
                                                    verified={data.Seller.is_verify}
                                                    basic={data.Seller.is_basic}
                                                    standard={data.Seller.is_standard}
                                                    premium={data.Seller.is_premium}
                                                    check={props.check}
                                                    setCheck={props.setCheck}
                                                    btnClickPopUp={'1'}
                                                    setActivePopUp={setActivePopUp}
                                                    data={data}
                                                    index={index}
                                                />
                                            </td>
                                        )}
                                    </tr>
                                )
                            } else {
                                return ''
                            }
                        })}
                </tbody>
            </Table>

            <Pagination
                pageSkip={props.pageSkip}
                setPageSkip={props.setPageSkip}
                listItem={props.sellers}
                pageAll={props.pageAll}
            />
        </div>
    )
}
